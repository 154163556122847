<template>
    <div>
        <AlertMenu />
        <section class="listings-section">
            <div v-if="alertLoading">
                <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;"/>
            </div>
            <div v-else>
                <div v-if="properties.length === 0">
                    <h2 class="no-listing alert-page-title">
                        No listings
                    </h2>
                </div>
                <div v-else class="listings-container">
                    <h1 class="alert-page-title">
                        Latest Alerts
                    </h1>
                    <div class="slideshow-outer-container">
                        <vueper-slides
                            fixed-height="80vh"
                            class="no-shadow slideshow-container"
                            :arrows="true"
                            :bullets="false"
                            :visible-slides="3"
                            slide-multiple
                            :gap="3"
                            :slide-ratio="1 / 4"
                            :dragging-distance="200"
                            :breakpoints="{ 850: { visibleSlides: 2, slideMultiple: 2 }, 650:{visibleSlides: 1, slideMultiple: 1}}">
                            <vueper-slide v-for="(property, i) in properties" :key="`property_${i}`">
                                <template #content>
                                    <div class="slide_content">
                                        <p>
                                            {{ new Date(property.publishedAt).toLocaleDateString("en-US",{year: 'numeric', month: '2-digit', day: '2-digit', weekday:"long", hour: '2-digit', hour12: true, minute:'2-digit', second:'2-digit'})}}
                                        </p>
                                        <Divider />
                                        <Button class="btn orange-bg" @click="$router.push(`/property/${property.category === 'listing' ? 'listings': 'rentals'}/${property.Slug}`)">
                                            View property
                                        </Button>
                                        <img :src="property.FeaturedImage ? `${apiURL}${property.FeaturedImage.url}` : '/images/placeholder.png'" :alt="property.Address">
                                        <p>
                                            {{ property.Address }},
                                            <br>
                                            {{ property.City }}
                                        </p>
                                        <p>
                                            {{ property.Price | priceFormat }} <span v-if="property.Status === 'lease'"> /mo.</span>
                                        </p>
                                        <Button class="btn black-bg" @click="openDeleteProperty(property)">
                                            Delete
                                        </Button>
                                    </div>
                                </template>
                            </vueper-slide>
                        </vueper-slides>
                    </div>  
                </div>
            </div>
        </section>
        <Divider class="divider margin-y" />
        <section class="alerts-section">
            <div v-if="alertLoading">
                <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;"/>
            </div>
            <div v-else>
                <h1>
                    Manage Your Alerts
                </h1>
                <div class="alerts-control-header">
                    <div class="alerts-status-section">
                        <span class="active">Active ({{ alerts.filter(item=>item.Active).length }})</span>
                        <span>Inactive ({{ alerts.filter(item=>!item.Active).length }})</span>
                    </div>
                    <Button class="btn orange-bg" @click="$router.replace('/alert/create')" >
                        CREATE NEW ALERT
                        <img src="/images/icons/plus.svg" alt="Create Alert">
                    </Button>
                </div>
                <div class="alerts-list">
                    <div v-for="(alert, i) in alerts" class="alert-container" :key="`alert_${i}`">
                        <div class="alert-info">
                            <span class="status" :class="{active : alert.Active, inactive: !alert.Active}" />
                            <span :class="{inactive: !alert.Active}">{{ `${alert.Name} ${alert.Active ? '' : '(Inactive)'}` }}</span>
                        </div>
                        <div class="alert-control">
                            <div class="alert-control-container" @click="$router.replace(`/alert/${alert.id}`)">
                                <img src="/images/icons/edit-black.svg" alt="Edit Alert">
                                Edit
                            </div>
                            <div v-if="alert.Active" class="alert-control-container" @click="activateAlert(i)">
                                <img src="/images/icons/pause.svg" alt="Pause Alert">
                                Pause
                            </div>
                            <div v-if="!alert.Active" class="alert-control-container active" @click="activateAlert(i)">
                                <img src="/images/icons/play.svg" alt="Enable Alert">
                                Enable
                            </div>
                            <div class="alert-control-container" @click="openDelete(alert)">
                                <img src="/images/icons/delete.svg" alt="Delete Alert">
                                Delete
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="agents-section">
            <div v-if="agentsLoading">
                <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;"/>
            </div>
            <div v-else>
                <h1>
                    Select Your Agent
                </h1>
                <Divider class="divider" />
                <div class="agents">
                    <div class="agent-container" :class="{selected : selectedAgent === -1}" @click="selectAgent(-1)">
                        <img src="/images/cerda_zein_logo.png" alt="Any">
                        <p>
                            Any
                        </p>  
                    </div>
                    <div v-for="(agent, i) in agents" class="agent-container" :key="i" :class="{selected : selectedAgent === i}" @click="selectAgent(i)">
                        <img :src="`https://strapi.cerda-zein.com${agent.attributes.Image.data.attributes.url}`" :alt="agent.attributes.FirstName">
                        <p>
                            {{ agent.attributes.FirstName }}
                        </p>
                    </div>
                </div>
                <div class="button-container">
                    <Button class="btn orange-bg" @click="updateAgent" :disabled="!agentChanged">
                        SAVE AGENT
                    </Button>
                </div>
            </div>
        </section>
        <Dialog modal v-if="deleteAlertShow" :visible.sync="deleteAlertShow" :dismissableMask="true">
            <div class="dialog-content">
                <img src="/images/icons/exclamation.svg" alt="Warning">
                <h1 class="modal-title">
                    Delete Alert
                </h1>
                <Divider />
                <p>
                    Are you sure you want to delete the following alert?
                    <br>
                    <b>{{ currentDeleteAlert.Name }}</b>
                </p>
                <div class="modal-buttons">
                    <Button class="btn black-bg" @click="deleteAlertShow = false">
                        Cancel
                    </Button>
                    <Button class="btn orange-bg" @click="deleteAlert">
                        Delete
                    </Button>
                </div>
            </div>
        </Dialog>
        <Dialog modal v-if="deletePropertyShow" :visible.sync="deletePropertyShow" >
            <div class="dialog-content">
                <img src="/images/icons/exclamation.svg" alt="Warning">
                <h1 class="modal-title">
                    Delete Property
                </h1>
                <Divider />
                <p>
                    Are you sure you want to delete the following property from your alerts?
                    <br>
                    <b>{{ currentDeleteProperty.Address }}, {{ currentDeleteProperty.City }}</b>
                </p>
                <div class="modal-buttons">
                    <Button class="btn black-bg" @click="deletePropertyShow = false">
                        Cancel
                    </Button>
                    <Button class="btn orange-bg" @click="deleteProperty">
                        Delete
                    </Button>
                </div>
            </div>
        </Dialog>
    </div>
</template>

<script>
import { apiURL } from '../../util.js'
import AlertMenu from '../../components/AlertMenu.vue'
import Spinner from 'vue-simple-spinner';
import Divider from 'primevue/divider';
import Button from 'primevue/button';
import { VueperSlides, VueperSlide } from 'vueperslides'
import Dialog from 'primevue/dialog'

export default {
    name: "MyAccount",
    components: {
        AlertMenu,
        Spinner,
        Divider,
        Button,
        Dialog,
        VueperSlides,
        VueperSlide
    },
    data(){
        return{
            apiURL,
            user: {},
            agents: [],
            agentsLoading: true,
            selectedAgent: -1,
            agentChanged: false,
            alertLoading: true,
            deleteAlertShow: false,
            deletePropertyShow: false,
            currentDeleteAlert: null,
            currentDeleteProperty: null,
        }
    },
    metaInfo: {
        title: 'Manage your alerts',
        titleTemplate: '%s | Cerda-Zein',
        meta: [{
            name: 'description', 
            content: "Cerda-Zein"
        }]
    },
    async mounted(){
        await this.getUserInfo();
        this.alertLoading = false;
        this.axios.get('https://strapi.cerda-zein.com/api/agents?populate=*&filters[showOnAlert][$eq]=true')
        .then((response) => {
            this.agents = response.data.data;
            this.agentsLoading = false;
            if(this.user.data.agent){
                this.selectedAgent = this.agents.findIndex(agent=> agent.id === this.user.data.agent.id);
            }
        })
    },
    methods: {
        async getUserInfo() {
            try {
                const header = this.$store.getters.authHeader;
                this.user = await this.axios.get(`${apiURL}/api/users/custom-self/1?populate[0]=agent&populate[1]=alerts&populate[2]=alerts.listings&populate[3]=alerts.rental&populate[4]=alerts.listings.FeaturedImage&populate[5]=alerts.rentals.FeaturedImage`,header);
            } catch(e) {
                this.error = true;
                this.user = {};
            }
        },
        async updateUser(data) {
            try {
                const tobeSubmitted = data;
                await this.axios.post(`${apiURL}/api/users/user-update/`, tobeSubmitted);
                // close dialog, go to my profile or my alert (because logged in)
            } catch(e) {
                this.error = true;
            } 
        },
        selectAgent(index){
            if(index !== this.selectedAgent){
                this.agentChanged = true;
            }
            this.selectedAgent = index;
        },
        async updateAgent(){
            const header = this.$store.getters.authHeader;
            if(this.selectedAgent === -1){
                await this.axios.put(`${apiURL}/api/users/user-update/${this.user.data.id}`,{agent:[]},header);
            } else {
                const data = {
                    agent: [this.agents[this.selectedAgent].id]
                }
                await this.axios.put(`${apiURL}/api/users/user-update/${this.user.data.id}`,data,header);
            }
            this.agentChanged = false;
        },
        async activateAlert(index){
            const header = this.$store.getters.authHeader;
            const alert = this.alerts[index];
            await this.axios.put(`${apiURL}/api/alert-manage/${alert.id}`,{Active:!alert.Active},header);
            this.getUserInfo();
        },
        openDelete(alert){
            this.currentDeleteAlert = alert;
            this.deleteAlertShow = true;
        },
        async deleteAlert(){
            const header = this.$store.getters.authHeader;
            const alert = this.currentDeleteAlert;
            await this.axios.delete(`${apiURL}/api/alert-manage/${alert?.id}`,header);
            this.getUserInfo();
            this.deleteAlertShow = false;
        },
        openDeleteProperty(property){
            this.currentDeleteProperty = property;
            this.deletePropertyShow = true;
        },
        async deleteProperty(){
            const header = this.$store.getters.authHeader;
            const property = this.currentDeleteProperty;

            const request = {
                [property.category === 'listing' ? 'listings' : 'rentals'] :{
                    disconnect: [property.id]
                }
            }

            // to be determined
            await this.axios.put(`${apiURL}/api/alert-manage/${property.alertId}`, request ,header);


            this.getUserInfo();
            this.deletePropertyShow = false;
        },

    },
    computed:{
        alerts(){
            return this.user.data.alerts;
        },
        properties(){
            return this.user.data.alerts.map((alert) => {
                const listings = alert.listings.map((listing) => {
                    return {
                        ...listing,
                        alertId: alert.id,
                        category: 'listing'
                    }
                })
                const rentals = alert.rentals.map((listing) => {
                    return {
                        ...listing,
                        alertId: alert.id,
                        category: 'rental'
                    }
                })
                return [...listings, ...rentals]
            }).flatMap(item=> item).sort((a,b) => {return new Date(b.updatedAt) - new Date(a.updatedAt);})
        },
    }
}
</script>

<style lang="scss" scoped>
h1{
    text-align: center;
    margin: 0;
    padding: 30px 0;
}
.divider{
    width: 90%;
    margin: 0 auto;
}
.agents{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 60px 80px;
    width: 90%;
    margin: 40px auto;
}
.agent-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    &:hover{
        cursor: pointer;
    }
    img{
        display: block;
        width: 100%;
        aspect-ratio: 1 / 1;  /* defining the aspect ratio of the image */
        object-fit: cover;  
    }
    p{
        text-transform: uppercase;
    }
    &.selected{
        padding: 16px;
        border: 4px solid #EF6A40;
    }
}

.button-container{
    display: flex;
    justify-content: center;
}

.alerts-section, .listings-section{
    background: #F5F5F5;
}
.alerts-control-header{
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 5%;
}
.alerts-status-section{
    display: flex;
    gap: 20px;
}
.alerts-list{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 60px 0;
}
.alert-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    margin: 10px 5%;
    background: #fff;
}
.alert-info, .alert-control, .alert-control-container{
    display: flex;
    gap: 30px;
    align-items: center;
}
.alert-control-container{
    gap: 10px;
    &:hover{
        cursor: pointer;
        opacity: 0.75;
    }
}
.status{
    width: 20px;
    height: 20px;
    border-radius: 10px;
    &.active{
        background: #66B253;
    }
    &.inactive{
        background: #8F8F8F;
    }
}

.active{
    color: #EF6A40;
}
.inactive{
    color: rgba(0,0,0,0.5);
}

.slideshow-outer-container{
    padding: 0 100px;
}

.slideshow-container{
    min-height: 500px;
}

.slide_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    padding: 30px;
    border-radius: 16px;
    img{
        display: block;
        margin: 20px auto;
        width: 100%;
        height: 200px;
        object-fit: cover;
    }
}
.no-listing{
    padding: 40px 0;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 20px;
    opacity: 0.4;
}
.slide-button{
    text-transform: uppercase;
    padding: 10px 20px;
    justify-content: center;
}
.agents-section{
    padding-bottom: 40px;
}

.alert-control-container{
    &:hover{
        color:#EF6A40;
        svg{
            fill: #EF6A40;
        }
    }
}

.margin-y{
    margin: 70px auto;
}

h1{
    font-size: 2rem;
}

@media (max-width: $tablet) {
    .agents{
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: $mobile) {
    .agents{
        grid-template-columns: repeat(2, 1fr);
        gap: 40px 30px;
    }
    .alerts-control-header{
        flex-direction: column-reverse;
        gap: 40px 0;
    }
    .alert-container{
        flex-direction: column;
        padding: 20px 0;
    }
    .alert-info{
        padding-bottom: 20px;
    }
    .alert-control{
        border-top: 1px solid #ccc;
        padding-top: 20px;
    }
    .slideshow-outer-container{
        padding: 0 22px;
    }
    .slide_content{
        text-align: center;
        img{
            margin: 12px auto;
        }
        p{
            margin: 8px auto;
        }
    }
    .slideshow-container{
        min-height: 300px;
    }
}
</style>